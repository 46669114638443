.account-settings {
  margin-top: 2em;
}

.account-list > li {
  list-style: none;
  font-size: 13px;
}

.account-title {
  color: #fff;
  margin-left: 2em;
  margin-top: 15px;
  margin-bottom: 15px;
}

.account-list__items {
  color: #fff;
}

.basicsettings-body form .form-body {
  padding-left: 0px !important;
  margin-left: 0px !important;
  padding-right: 0px !important;
}

.sideBar {
  background: black;
  height: 100%;
  padding-top: 2em;
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.69);
}

.pwd_S div.col-sm-3 {
  padding-right: 0px !important;
}

.account-logout {
  margin-top: 13em;
  margin-left: -2em;
}

.account-list {
  margin-left: 3em;
}

.flex-column {
  border: none !important;
}
