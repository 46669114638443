.cat-card > h5 {
	overflow: hidden;
}
.partners-two {
	display: none;
}

.partners-three {
	display: none;
}

.partners-four {
	display: none;
}

@media (max-width: 767px) {
	.partners-one {
		display: none;
	}
	.partners-two {
		display: block;
	}
	.cat-top {
		margin-top: 22px;
	}
}

@media (max-width: 602px) {
	.partners-two {
		display: none;
	}
	.partners-three {
		display: block;
	}
}

@media (max-width: 423px) {
	.partners-three {
		display: none;
	}
	.partners-four {
		display: block;
	}
}
