.faq-background-image {
	/* background: url("/image/questions.jpg"); */
	width: 100%;
	min-height: 25rem;
	background-size: cover;
	background-repeat: no-repeat;
	/* margin-top: 75px; */
}

.contact-background-image {
	/* background: url("/image/contact-us.png"); */
	width: 100%;
	min-height: 25rem !important;
	background-size: cover;
	background-repeat: no-repeat;
	/* margin-top: 75px; */
}

#message {
	border-radius: 5px !important;
}

img.contactIcon {
	margin-top: 10px;
}
p.addressC {
	margin-top: -10px;
}
.background {
	position: relative;
}
.faq-background-text h1 {
	font-size: 25px;
	font-family: "Avenir-Medium";
}

.faq-background-text p {
	font-size: 16px;
	font-family: "Avenir-Medium";
	color: #dfdfdf;
}
.layer {
	background: rgba(27, 57, 7, 0.53);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.faq-background-text {
	text-align: center;
	color: white;
	margin-top: 10rem;
}

.faq-cant-find {
	text-align: center;
	margin-top: 5em;
	color: white;
}

.faq-cant-find__subtitle {
	color: lightgray;
}

.faq-body {
	padding-top: 5em;
	padding-bottom: 5em;
	background-color: #181818;
}

.faq-item {
	background-color: transparent !important;
}

.faq-list {
	color: white !important;
	font-size: 22px !important;
	padding-bottom: 1.2em !important;
	font-family: "Avenir-Medium";
}

.faq-list:hover,
.faq-list:focus {
	text-decoration: none !important;
	background-color: transparent !important;
	border-color: transparent !important;
	outline: none;
}

.faq-content {
	font-size: 18px;
	color: #888888;
	border-bottom: 0.1px solid #888888;
	color: rgba(255, 255, 255, 0.9);
	margin-left: 15px;
	margin-right: 15px;
}

.faq-item-number {
	border-bottom: 0.1px solid #888888;
}

.accordion {
	background-color: transparent;
	color: #fff;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	border: none;
	text-align: left;
	outline: none;
	font-size: 15px;
	transition: 0.4s;
	font-size: 20px;
	/* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
	font-family: "Avenir-Medium";
}

.active,
.accordion:hover {
	background-color: transparent;
}

.accordion:after {
	content: '\002B';
	color: #777;
	font-weight: bold;
	float: right;
	margin-left: 5px;
}

.active:after {
	content: "\2212";
}

.accordionBody {
	margin-top: 80px;
}

.panel {
	padding: 0 18px;
	background-color: transparent;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
	color: #e0e0e0;
	font-size: 16px;
	font-family: "Avenir-Roman";
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	/* padding-bottom:15px; */
}

.faq-cant-find {
	background: rgba(0, 0, 0, 0.5);
	padding: 50px 0;
}
