.email-title {
	color: white;
	margin-top: 20px;
}

.basicsettings-title {
	margin-top: 20px;
	margin-right: 38px;
}

.basicsettings-container {
	padding-bottom: 1.5em !important;

	margin-bottom: 0px !important;
}

.email-news {
	color: white;
}

.email-email {
	color: white;
}

.email-notification {
	color: white;
}

.email-save {
	margin-top: 25px;
}

.checkbox {
	padding-left: 20px;
	
}

.checkbox label {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	padding-left: 15px;
	color: white;
}

/* .checkbox label::before {
	content: "";
	display: inline-block;
	position: absolute;
	width: 17px;
	height: 17px;
	left: 0;
	margin-left: -20px;
	border-radius: 3px;
	background-color: #fff;
	-webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
	-o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
	transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
} */
/* 
.checkbox label::after {
	display: inline-block;
	position: absolute;
	width: 16px;
	height: 16px;
	left: 0;
	top: 0;
	margin-left: -20px;
	padding-left: 3px;
	padding-top: 1px;
	font-size: 11px;
	color: #555555;
} */

.checkbox input[type="checkbox"] {
	opacity: .8;
	z-index: 1;
	display: inline-block;
}

.checkbox input[type="checkbox"]:focus + label::before {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	/* outline-offset: -2px; */
}

/* .checkbox input[type="checkbox"]:checked + label::after {
	content: "\2714";
} */

.checkbox-success input[type="checkbox"]:checked + label::before {
	background-color: #5cb85c;
	border-color: #5cb85c;
}

.checkbox-success input[type="checkbox"]:checked + label::after {
	color: #fff;
}

.closeacct p {
	color: #ffffffd4;
	font-family: "Avenir-Roman";
}

.closeacct p span {
	color: #fff;
	text-decoration: capitalize;
}

form#closeacct .col-form-label {
	color: #f04;
}
