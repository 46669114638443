/*event attendees*/

.eventmanager-row {
	margin-right: 0 !important;
}
.event-attendees-container {
	color: white !important;
	margin-top: 53px;
}

/*.eventmanager-container {

  background-color: #181818;
  padding-left: 2em;
  padding-top: 5em;
  padding-bottom: 7em;
  margin-left: 0em;
  margin-top: 4.4em;
  margin-bottom: -70px;

  padding-right: 4em
}

.event-attendees-container>h2 {
  margin-bottom: 30px;

} */

.eventmanager-navbar {
	list-style: none;
	display: flex;
	justify-content: flex-end;
	margin-right: 25px;
	color: #989898;
}

.eventmanager-navbar__items {
	margin-left: 10px;
	padding-left: 10px;
	border-left: 0.1px solid #989898;
}

.eventmanager-navbar__unpublish {
}

.eventmanager-title {
	margin-top: 10px;
	color: white;
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	padding-bottom: 12px;
	margin-left: -13px;
	margin-right: 32px;
}

div.col-md-9.mt-5.eventmanager-container {
	padding-left: 35px;
}

.event-attendees {
	display: flex;
}

.row-event-attendees {
	margin-bottom: 20px;
}

.event-attendees__table {
	background-color: white;
	border-radius: 8px;
	padding: 1em 1em 0 1em;
	color: #181818;
}
h4.colorwhite,
h2.colorwhite,
p.colorwhite {
	color: #fff;
}
.event-attendees__table-top {
	display: flex;
	padding-bottom: 1em;
}

.btn-event {
	border: none;
	background-color: transparent;
}

.btn-event {
	color: #74aa50;
}

.btn-event:focus {
	outline: 0 !important;
}

.section-one__a {
	background-color: #fff;
	border-radius: 10px;
	max-width: 47% !important;
}

.section-one__b {
	background-color: #fff;
	border-radius: 10px;
	max-width: 47% !important;
	margin-left: 1em;
}

.section-two__a {
	background-color: #fff;
	border-radius: 10px;
	max-width: 47% !important;
}

.section-two__b {
	background-color: #fff;
	border-radius: 10px;
	/* max-width: 47% !important; */
	/* margin-left: 1em; */
}

.noLeft {
	margin-left: 0px !important;
	margin-bottom: 60px;
}

.event-attendees__text {
	margin-right: 15px;
	margin-top: 7px;
}
.section-two.container-fluid {
	padding-left: 0px !important;
}
.event-attendees__message {
	background: none;
	border: 1px solid #74aa50;
	border-radius: 4px;
	color: #fff;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-right: 10px;
}

.event-message__icon {
	color: #74aa50;
	margin-right: 10px;
}

.event-search__icon {
	color: grey;
	padding-top: 11px;
	padding-left: 12px;
}

.event-search {
	background-color: transparent;
	border: none;
}

.event-search:focus {
	outline: 0 !important;
}

.event-search-class {
	display: flex;
	background-color: white;
	border-radius: 8px;
}

/*Report*/
.event-report {
	background-color: white;
	border-radius: 8px;
	padding-top: 5em;
	padding-bottom: 5em;
}

.event-report__image {
	display: flex;
	justify-content: center;
}

.event-report__text {
	padding-bottom: 10px;
	text-align: center;
	color: #181818;
	padding-left: 2em;
	padding-right: 2em;
}

.event-report__form {
	width: 50%;
}

thead {
	color: black;
}

/*Basic Information*/

/* .event-3 {
	margin-top: 74px !important;
	padding-right: 0px;
} */

.edit-card-ticket {
	height: auto !important;
}

/*event promote*/

.event-promote-container {
	/* background-color: #181818;
	padding-left: 2em;
	padding-top: 5em;
	padding-bottom: 7em;
	margin-left: 0em;
	margin-top: 4.4em;
	/*margin-bottom: -70px;*/
	/* padding-right: 4em; */
}

.btn-share {
	background: #74aa50 !important;
	border-radius: 4px !important;
	border: none !important;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 2px;
	color: white !important;
	font-size: 20px;
}

.event-promote__bg {
	display: flex;
}

.event-promote {
	background: white;
	border-radius: 8px;
	margin-bottom: 20px;
	padding-bottom: 20px;
	padding-top: 20px;
	padding-left: 20px;
}

.event-promote__social {
	margin-right: 20px;
	font-size: 27px;
	margin-left: -13px;
}

.event-promote__social-g {
	color: rgb(237, 82, 13);
}

.event-promote__social-tw {
	color: rgb(5, 159, 245);
}

.event-promote__social-fb {
	color: #3a66e5;
}

.event-promote > p {
	color: #686868;
}

.event-promote-container > h2 {
	color: white;
}

.event-promote-container > p {
	color: white;
	margin-bottom: 30px;
}

/*Event settings*/

.event-settings {
	background-color: white;
	border-radius: 8px;
	padding: 15px;
	height: 100%;
}

.event-settings > h4 {
	color: black;
	font-weight: 700;
	padding-bottom: 12px;
	font-size: 16px;

}

.event-settings > p {
	color: #a4a4a4;
}

.btn-settings {
	margin-top: 2em;
}

@media (max-width: 991px) {
	.event-settings-row {
		padding-bottom: 15px;
	}
}

.save-btn {
	margin-left:15px;
}

@media (max-width: 445px) {
	.save-btn {
		margin-left: 0px;
		margin-top:20px;
		display: block;
	}
}

@media (min-width: 575px) and (max-width: 600px){
	.save-btn {
		margin-left: 0px;
		margin-top:20px;
		display: block;
	}
}

/*Event questions*/
@media only screen and (max-width: 575px) {
	.event-questions-container h2 {
		font-size: 1.375rem; 
	}
}

.event-questions-container {
	color: white !important;
	padding-left: 2em;
	/* padding-top: 5em; */
	margin-left: 0em;
	margin-top: 55px;
	margin-bottom: 50px;
	/*margin-bottom: -70px;*/
	padding-right: 4em;
}
.event-questions__header-row {
	display: flex;
	border-bottom: 1px solid #c0c0c0;
	padding-bottom: 6px;
}

.event-questions__left-body {
	margin-top: 30px;
}


.asterisk {
	color: red;
}

.event-questions__right {
	background-color: #26262699;
	padding: 30px;
}

.event-questions__right-header {
	background-color: white;
	color: black;
	border-radius: 8px;
	/* margin: 30px 10px 100px 10px; */
	padding: 20px;
}

.event-questions__right-header > h4 {
	margin-bottom: 25px;
	border-bottom: 1px solid #c0c0c0;
	padding-bottom: 6px;
}

.form-control__questions {
	height: 3em;
}

.btn-question {
	background: #74aa50 !important;
	border-radius: 4px !important;
	border: none !important;
	color: white !important;
	margin-left: 10px;
	padding: 5px 10px 5px 10px;
}

.btn-question {
	color: #74aa50;
}

.event-questions__button {
	margin-left: auto;
}

.edit-event-side,.edit-eventclass-side {
  margin-left: 40px;
}

@media (max-width:767px) {
  .event-questions-container,
  .event-promote-container {
    padding-right: 15px;
}
}

#main-menu {
	background-color: transparent;
}
a.list-group-item.active {
	color: #fff;
	background-color: rgba(116, 170, 80, 0.18) !important;
	border-left: 3px solid #74aa50 !important;
}
.list-group-item {
	background-color: transparent !important;
	border: none;
}

a.list-group-item {
	color: #fff !important;
	padding-top: 20px;
	padding-bottom: 20px;
	font-size: 17px;
}

a.list-group-item.s__active {
	color: rgb(116, 170, 80) !important;
	font-size: 17px;
	font-family: "Avenir-Medium";
}

a.list-group-item:hover,
a.list-group-item:focus {
	background-color: #43d5b1;
}

a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
	color: #fff;
	background-color: #43d5b1;
	border: none;
}

.list-group-item:first-child,
.list-group-item:last-child {
	border-radius: 0;
}

.list-group-level1 .list-group-item {
	padding-left: 30px;
}

.list-group-level2 .list-group-item {
	padding-left: 60px;
}
.eventmanager-row {
	margin-right: 0px !important;
}

.drawer-menu-container {
display: none;
margin-top: 76px;
}


@media (max-width: 767px) {
	.drawer-menu-container{
		display: block;
	}
	.event-3 {
		display: none;
	}
}