
.background {
	position: relative;
}

.contact-background-text {
	text-align: center;
	color: white;
	margin-top: 14em;
}

.contactus-body {
	margin-left: auto;
	margin-right: auto;
}

.contactus-contact {
	display: flex;
	padding-top: 5em;
	padding-bottom: 2em;
}

.contactus-contact__home {
	display: flex;
	padding-top: 4em;
	padding-bottom: 2em;
}

.contactus-contact__text {
	color: white;
	margin-left: 1em;
}

.contactus-contact__icon {
	color: white;
	font-size: 2em;
}

.icon-home {
	padding-top: 10px;
}

.contactus-textarea {
	border-radius: 5px;
}

.contactus-send {
	padding-left: 2em !important;
	padding-right: 2em !important;
}
