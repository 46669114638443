@font-face {
	font-family: Avenir-Black;
	src: url('./font/Avenir-Black.ttf');
}

@font-face {
	font-family: Avenir-Heavy;
	src: url('./font/Avenir-Heavy.ttf');
}

@font-face {
	font-family: Avenir-Medium;
	src: url('./font/Avenir-Medium.ttf');
}

@font-face {
	font-family: Avenir-Roman;
	src: url('./font/Avenir-Roman.ttf');
}

#time {
	border-left: 0;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.card {
	background-color: transparent !important;
}

#fullCarousel {
	color: transparent;
}
/*.carousel-control-prev-icon {
  background-image: url(/image/angel-right.png) !important;
}*/

button.close {
	/* position: absolute; */
}

a {
	color: #74aa50;
	transition: color 300ms ease-in-out;
}

a:hover {
	text-decoration: none;
	color: #ffffffd4;
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:visited {
	text-decoration: none;
	position: relative;
	color: #74aa50;
}

.addCard a:hover {
	color: #a4a4a4;
}

.navbar-dark .navbar-nav .nav-link {
	font-size: 15px;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:active {
	color: #74aa50 !important;
}

.navbar-dark .nav-item .btn-main {
	font-size: 14px;
}
/* .navbar-dark .navbar-nav .nav-link:after,
.navbar-dark .navbar-nav .nav-link:visited:after {
	content: '';
	height: 3px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0%;
	background: #74aa50;
	transition: 0.2s;
} */
.navbar-dark .navbar-nav .nav-link:hover:after,
.navbar-dark .navbar-nav .nav-link:visited:hover:after {
	width: 100%;
}
.navbar-dark .navbar-nav .nav-link.block,
.navbar-dark .navbar-nav .nav-link:visited.block {
	display: block;
	padding: 0.5em;
}

.imageEvent::after {
	content: "" !important;
}

button:focus {
	outline: none !important;
}

.event-details {
	bottom: 0;
	position: absolute;
	padding-bottom: 15px;
	padding-left: 20px;
	padding-right: 20px;
	display: block;
	top: 30px;
}

.event-details span {
	font-size: 14px;
}

.event-name span {
	font-size: 18px !important;
	font-weight: 800 !important;
}

div.event-details a.event-title {
	font-size: 18px;
	color: #74aa50;
	padding-bottom: 5px;
	font-family: "Avenir-Heavy";
	line-height: 90%;
	transition: color 300ms ease-in-out;
}

div.event-details a.event-title:hover {
	text-decoration: none;
	color: #ffffffd4;
}

p.event-icon img {
	margin-top: 4px;
}

.price-btn {
	margin-top: -7px;
	float: left;
}

.event-details p {
	font-size: 14px;
	color: #fff;
	font-family: Avenir-Roman;
}

p span.price {
	font-weight: 600;
	font-family: "Avenir-Heavy";
}

.appsBottom {
	padding-top: 15px;
}

body {
	width: 100%;
	margin: 0px;
	font-family: Avenir-Roman !important;
	letter-spacing: 0.5 !important;
	background-color: #0d0c10 !important;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
#root {
	height: 0px;
}

/* .container-fluid {
	padding-left: 30px !important;
	padding-right: 30px !important;
} */

.allEvents {
	margin-right: 4.5rem;
}

.page-title span a {
	font-size: 14px;
	font-family: "Avenir-Roman";
}

.page-title {
	font-family: "Avenir-Medium";
	font-size: 25px;
	color: #fff;
	margin-bottom: 20px;
}

/* LOGO */

.navbar-brand img {
	width: 154.74px !important;
	height: 48px !important;
}

/* NAVIGATION */

.navbar-dark .navbar-nav .nav-link {
	color: #fff !important;
	padding-left: 25px;
	padding-right: 25px;
}

li.nav-item:last-child {
	/* padding-left: 25px; */
}

.navbar-collapse {
	flex-grow: 0 !important;
}

#fullcarousel {
	top: 0;
	position: absolute;
}

/* BUTTON */

.btn-buy {
	background: #74aa50 !important;
	border-radius: 4px !important;
	border: none !important;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 2px;
	color: white !important;
}

.btn-buy {
	color: white;
}

.btn {
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: transparent;
	/* border: 2px solid #e74c3c; */
	border-radius: 4px;
	color: #fff;
	cursor: pointer;
	/* display: flex; */
	align-self: center;
	/* padding: 8px 16px; */
	text-decoration: none;
	text-align: center;
}

.btn-ticket {
	transition: box-shadow 500ms ease-in-out, color 500ms ease-in-out;
	color: #fff;
}
.btn-ticket:hover {
	box-shadow: 0 0 40px 40px #74aa50 inset;
	color: #fff !important;
}

.btn-ticket a:hover {
	/* box-shadow: 0 0 40px 40px #74aa50 inset; */
	color: #fff !important;
}

.btn-main {
	border-color: #74aa50;
	border-radius: 4px;
	background: #74aa50;
	color: #fff !important;
	/* box-shadow: 0 0 40px 40px #74aa50 inset, 0 0 0 0 #74aa50; */
	transition: all 300ms ease-in-out;
}
.btn-main:hover {
	background: transparent;
	/* box-shadow: 0 0 10px 0 #74aa50 inset, 0 0 10px 4px #74aa50; */
}

.btn-white-main {
	border-color: #74aa50;
	border-radius: 4px;
	background: #74aa50;
	color: #fff !important;
	/* box-shadow: 0 0 40px 40px #74aa50 inset, 0 0 0 0 #74aa50; */
	transition: all 300ms ease-in-out;
}
.btn-white-main:hover {
	background: transparent;
	color: #74aa50 !important;
	/* box-shadow: 0 0 10px 0 #74aa50 inset, 0 0 10px 4px #74aa50; */
}

.btn-ticket,
.btn-save {
	background: none;
	border: 1px solid #74aa50;
	border-radius: 4px;
	color: #fff;
	padding-left: 20px;
	padding-right: 20px;
}

.btn-main a:hover {
	color: #fff !important;
}

.btn-save {
	color: #74aa50;
}

/* SLIDER CAROUSEL */

.navbar {
	z-index: 9;
	position: relative;
	width: 100%;
	background-color: rgb(0, 0, 0) !important;
	top: 0 !important;
}

.carousel-caption {
	top: 30% !important;
}

.carousel-caption h5 {
	font-size: 50px;
	text-align: center;
	color: #ffffff;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.52);
}

.faqBkg {
	background: url("./image/questions.jpg ");
	background-size: cover;
	background-repeat: no-repeat;
	background-color: rgba(27, 57, 7, 0.49);
	background-blend-mode: multiply;
	position: absolute;
	max-width: 350px;
	min-width: 200px;
	left: 0;

	border: none;
	color: #74aa50 !important;
}
.faqContent {
	position: relative;
}
/* Search section */

.searchContainer {
	margin-top: 5rem;
}

.s-search {
	margin: 0 auto;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
	background: transparent;
}

.explain textarea {
	width: 100%;
}

.search-section {
	background-color: #fff;
	border-radius: 8px;
	margin: 0 auto;
	position: absolute;
	padding-left: 3em;
	padding-right: 3em;
	padding-bottom: 20px;
	left: 0;
	right: 0;
	margin-top: -70px;
	box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 1);
}

.search-section h4 {
	font-size: 30px;
	text-align: center;
	padding-top: 20px;
	font-family: "Avenir-Medium";
}

.input-group-text {
	background-color: #fff;
	border: 1px solid #ced4da;
	border-bottom-left-radius: 0px;
	border-top-left-radius: 0px;
	border-left-width: 0px;
	border-right-width: 0px;
}

input#location,
input#time {
	border-radius: 0;
	border-left-width: 0px;
}

input#question,
input#question1 {
	border-bottom-right-radius: 0px !important;
	border-top-right-radius: 0px !important;
	border-radius: 5px;
	width: 80%;
}

input#question1 {
	width: 60% !important;
}

.homesearch-input .input-group-prepend {
	border-top: 1px solid #ced4da;
	border-top-left-radius: 0px;
	border-bottom: 1px solid #ced4da;
	border-bottom-left-radius: 0px;
}

.src-btn {
	background: #74aa50 !important;
	border-bottom-left-radius: 0px;
	border-top-left-radius: 0px;
	border-color: #74aa50;
	padding-left: 20px;
	padding-right: 20px;
}

.src-btn:hover {
	background: #346513 !important;
	border-color: #74aa50;
}

select {
	-moz-appearance: none; /* Firefox */
	-webkit-appearance: none; /* Safari and Chrome */
	appearance: none;
}

.search-section > form > div.input-group {
	width: 18%;
}

.search-section > .form-inline {
	margin-left: 45px;
}

.search-section .form-control:focus {
	border-color: #ced4da !important;
	box-shadow: unset !important;
}

/* trending Events */

.popularEvents {
	margin-top: 140px;
}

.trendingEvents,
#categories,
#brand_logo {
	margin-top: 50px;
}

.trendingEvents {
	padding-left: 0.8rem;
	padding-right: 0.8rem;
}

#merch_free {
	margin-top: 80px;
}

/* #categories .row {
  margin: 0px;
} */

.card {
	border: 0px;
}

.card {
	height: 400px;
	border-radius: 8px;
	overflow: hidden;
	/* transition: box-shadow 300ms ease-in-out; */
}

/* .card:hover {
	-webkit-box-shadow: 0px 17px 33px -6px rgba(109, 163, 156, 1);
	-moz-box-shadow: 0px 17px 33px -6px rgba(109, 163, 156, 1);
	box-shadow: 0px 17px 33px -6px rgba(109, 163, 156, 1);
} */
#disCode {
	height: calc(2.65rem + 2px) !important;
}
.merch-img {
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.25);
}

#time {
	border-left: 0px;
}

.merch-img div.event-details {
	top: unset !important;
}

.merch-img #merch-product {
	width: 88% !important;
	margin: 0 auto;
	padding-top: 13px;
}

img.eventDescription {
	max-height: 400px;
	width: 100%;
	/* object-fit: cover; */
	margin-bottom: 0.5rem;
	min-height: 150px;
}
.card-image {
	/*object-fit: cover;*/
	/* object-position: 100% 100%; */
	/*height: 100%;*/
	position: relative;
	width: 100%;
	/*max-height: 200px;*/
	position: absolute;
	top: 0;
}

/* .card-image:before {

      background: linear-gradient(181deg, rgba(0, 0, 0, 0) 6.84%, #000000 73.98%) !important;
  } */

.mini-overlay {
	background: linear-gradient(181deg, rgba(0, 0, 0, 0) 11.84%, #000000 72.98%);
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.details-section {
	background: #1d2125;
	width: 100%;
	height: 50%;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
}

.slick-dots {
	display: none;
}
a.carousel-control-prev:before {
	content: "";
	background: black;
	height: 108px;
	width: 40px;
	position: absolute;
	z-index: -1;
	left: 0;
}

a.carousel-control-next:before {
	content: "";
	background: black;
	height: 108px;
	width: 40px;
	position: absolute;
	z-index: -1;
	right: 0;
}

.carousel-control-prev-icon {
	position: absolute;
	left: 0;
	margin-left: 0.5rem;
}

.carousel-control-next-icon {
	position: absolute;
	right: 0;
	margin-right: 0.5rem;
}

/* .carousel-control-prev,
.carousel-control-next {
	width: 3% !important;
} */

.event-date {
	background: url('./image/date-card.png');
	width: 48px;
	height: 58px;
	font-family: "Avenir-Medium";
	position: absolute;
	right: 0;
	color: #fff;
	font-size: 16px;
	line-height: 110%;
	top: -29px;
	margin-right: 20px;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.48);
}

.event-date span {
	text-align: center;
	position: absolute;
	padding: 12px 7px;
}

.event-date__day {
	margin-left: 6px;
}

.event-month {
	margin-top: 20px;
}

.other-info {
	top: 14%;
}

.card-image-container {
	height: 100%;
}

.cat-card {
	border: 1px solid rgba(182, 178, 178, 0.45);
	border-radius: 8px;
	min-height: 130px;
	position: relative;
}

.cat-card > h5 {
	vertical-align: middle;
	text-align: center;
	font-family: "Avenir-Roman";
	color: #fff;
	top: 41%;
	position: absolute;
	left: 0;
	right: 0;
}

.zoom:hover {
	transform: scale(1.5);
}

#fashion {
	transition: background 300ms ease-in-out, background-color 300ms ease-in-out;
}

#fashion h5 a,
#sport h5 a,
#exhibition h5 a,
#liveshow h5 a,
#food h5 a,
#music h5 a,
#comedy h5 a,
#social h5 a,
#plays h5 a,
#galleries h5 a,
#business h5 a,
#parties h5 a {
	color: #fff !important;
}

#fashion h5 a:hover,
#sport h5 a:hover,
#exhibition h5 a:hover,
#liveshow h5 a:hover,
#food h5 a:hover,
#music h5 a:hover,
#comedy h5 a:hover,
#social h5 a:hover,
#plays h5 a:hover,
#galleries h5 a:hover,
#business h5 a:hover,
#parties h5 a:hover {
	color: #74aa50 !important;
}

#fashion:hover,
#exhibition:hover,
#sport:hover,
#parties:hover,
#liveshow:hover,
#food:hover,
#music:hover,
#comedy:hover,
#social:hover,
#plays:hover,
#galleries:hover,
#business:hover {
	/* background: url(/image/fashion.png); */
	background-position: 100% 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: rgba(0, 0, 0, 0.771);
	background-blend-mode: multiply;
	border: none;
	color: #74aa50 !important;
}

#fashion:hover {
	background: url('./image/fashion.png');
	background-color: rgba(0, 0, 0, 0.700);
	background-repeat: no-repeat;
	background-size: cover;
}

#sport:hover {
	background: url('./image/sport.png');
	background-color: rgba(0, 0, 0, 0.700);
	background-repeat: no-repeat;
	background-size: cover;
}

#exhibition:hover {
	background: url('./image/exhibition.png');
	background-color: rgba(0, 0, 0, 0.700);
	background-repeat: no-repeat;
	background-size: cover;
}

#parties:hover {
	background: url('./image/parties.png');
	background-color: rgba(0, 0, 0, 0.700);
	background-repeat: no-repeat;
	background-size: cover;
}

#liveshow:hover {
	background: url('./image/liveshow.png');
	background-color: rgba(0, 0, 0, 0.700);
	background-repeat: no-repeat;
	background-size: cover;
}

#food:hover {
	background: url('./image/foods.png');
	background-color: rgba(0, 0, 0, 0.700);
	background-repeat: no-repeat;
	background-size: cover;
}
#business:hover {
	background: url('./image/business.png');
	background-color: rgba(0, 0, 0, 0.700);
	background-repeat: no-repeat;
	background-size: cover;
}

#music:hover {
	background: url('./image/musics.png');
	background-color: rgba(0, 0, 0, 0.700);
	background-repeat: no-repeat;
	background-size: cover;
}

#comedy:hover {
	background: url('./image/comedy.png');
	background-color: rgba(0, 0, 0, 0.700);
	background-repeat: no-repeat;
	background-size: cover;
}

#social:hover {
	background: url('./image/socials.png');
	background-color: rgba(0, 0, 0, 0.700);
	background-repeat: no-repeat;
	background-size: cover;
}

#plays:hover {
	background: url('./image/play.png');
	background-color: rgba(0, 0, 0, 0.700);
	background-repeat: no-repeat;
	background-size: cover;
}
#galleries:hover {
	background: url('./image/gallery.png');
	background-color: rgba(0, 0, 0, 0.700);
	background-repeat: no-repeat;
	background-size: cover;
}

#merchandize:after {
	height: 0.5px;
	position: absolute;
	width: 100px;
	background: rgba(255, 255, 255, 0.25);
	border-right: 0.5px white;
	content: "";
	-webkit-transform: rotate(90deg);
	right: 0;
	vertical-align: middle;
	top: 265px;
	margin-right: -51px;
}

footer {
	color: white;
}

footer h5 {
	font-family: "Avenir-Medium";
	color: #fff;
	font-size: 18px;
}

footer ul li {
	list-style: none;
	color: #ebebeb;
	font-family: "Avenir-Roman";
	padding-top: 10px;
	transition: all 300ms ease-in-out;
	font-size: 14px;
}

footer ul li:hover {
	padding-left: 10px;
	color: #74aa50;
}

footer p {
	padding-top: 10px;
}

#footer {
	/* margin-top: 70px; */
	/* border-top: 1px solid rgba(255, 255, 255, 0.15); */
	padding-top: 50px;
	padding-right: 15px;
	padding-left: 15px;
	background: rgba(0, 0, 0, 0.8) !important;
}
.footer__copyright {
	display: inline-flex;
	font-size: 14px;
}
.footer__links {
	display: inline-flex;
	float: right;
	font-size: 14px;
	color: #ebebeb;
}

.footer__links a{
	color: #ebebeb;
}

.footer__links a:hover{
	color: #74aa50;
}

.footer__links a:first-child {
	padding-right: 10px;
}

.footer__links a:last-child {
	padding-left: 10px;
}
.bottom-footer {
	margin-top: 20px;
	border-top: 1px solid rgba(255, 255, 255, 0.15);
	padding-top: 10px;
	padding-bottom: 10px;
}

.owl-nav {
	float: right;
	top: 0;
	position: absolute;
	right: 0;
	margin-top: -53px !important;
}

button span {
	color: #fff;
	padding-left: 8px;
	font-size: 22px;
}

button.owl-next:focus,
button.owl-prev:focus {
	outline: none !important;
}

.event-social .fab {
	color: #4f4f4f;
	font-size: 23px;
	padding-left: 15px;
}

.fab {
	color: white;
	font-size: 23px;
	padding-left: 15px;
}

.sliders {
	width: 70%;
	margin: 0 auto;
}

.slick-slide {
	margin: 0px 10px;
}

.slick-slide img {
	width: 100%;
}

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;
}

.slick-list:focus {
	outline: none;
}

.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;
}

.slick-track:before,
.slick-track:after {
	display: table;
	content: "";
}

.slick-track:after {
	clear: both;
}

.slick-loading .slick-track {
	visibility: hidden;
}

.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
}

[dir="rtl"] .slick-slide {
	float: right;
}

.slick-slide img {
	display: block;
	width: auto;
	height: 40px;
}

.slick-slide.slick-loading img {
	display: none;
}

.slick-slide.dragging img {
	pointer-events: none;
}

.slick-initialized .slick-slide {
	display: block;
}

.slick-loading .slick-slide {
	visibility: hidden;
}

.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
	display: none;
}

/* --------------------- Event Detials Page ---------------------------- */

.event-overlay {
	background: linear-gradient(180deg, rgba(13, 12, 16, 0) 97%, #0d0c10 100.13%, #0d0c10 5%);
	/* linear-gradient(180deg, rgba(13, 12, 16, 0) 37.43%, #0D0C10 53.13%, #0D0C10 58.36%); */
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

h5.event-h5 {
	font-size: 18px;
}

h4.event-header {
	font-size: 25px;
	color: #fff;
	margin-bottom: 5px;
}

h4.event-description {
	font-size: 22px;
	color: #fff;
	padding-top: 20px;
	padding-bottom: 9px;
}

.header-section {
	background: #1f1d23;
	border-top: 1px solid rgba(255, 255, 255, 0.114);
	border-bottom: 1px solid rgba(255, 255, 255, 0.114);
}

.description {
	/* padding-top: 30px; */
}

.description p {
	color: #ffffffd4;
	font-family: "Avenir-Roman";
}
/* new buyTicketNew page */
.buyticket-description {
	padding: 0 30px 30px 30px;
	background-color: #ffffff;
	color: #000000;
}

.buyticket-description p {
	font-family: "Avenir-Roman";
}

.PhoneInput, .buyticket-description input {
	padding: 15px;
}

.PhoneInput > input, .PhoneInput > input:focus, .PhoneInput > input:active {
	padding: 0;
	border: none !important;
	outline: none;
}

.description__ticket-select {
	background-color: #FFFFFF;
	margin-top: 2rem;
}

.description__ticket-select input {
	margin-right: .6rem;
}

.description__info-section {
	background-color: #F5F5F5;
	padding: 1rem;
	margin-top: 2rem;
}


.description__section-heading {
	font-size: 1.4rem;
	font-weight: 500;
}

.description__section-subheading {
	font-size: 1.1rem;
	font-weight: 200;
}


/* end new buyTicketNew page */

.event-pricing {
	background: #f5f5f5;
	/* margin-top: -64px; */
	/*max-height: 395px;*/
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	/*margin-right: -25px;*/
}

.event-pricing h4 {
	font-size: 22px;
	color: #4f4f4f;
	padding-top: 20px;
	padding-bottom: 9px;
	padding-left: 25px;
	padding-right: 25px;
}

h5 {
	color: white;
	font-family: "Avenir-Medium";
}
.partnerLogo .owl-carousel .owl-item img {
	width: auto;
	margin: auto;
}

.owl-item::after {
	visibility: hidden;
}

.partnerLogo {
	margin-bottom: 30PX !important;
}

#dateTime,
.location,
#tag {
	margin-top: 30px;
}

#dateTime p span {
	display: block;
}

#additionalInfo {
	margin-top: 60px;
	margin-bottom: 60px;
}

#tag div {
	display: inline-flex;
	padding: 10px 15px;
	border: 1px solid rgba(255, 255, 255, 0.114);
	margin-right: 10px;
	border-radius: 33px;
	color: #ffffffd4;
	cursor: pointer;
}

#tag div:hover {
	background: white;
	color: #74aa50;
	box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.25);
}

#tag h5 {
	margin-bottom: 25px;
}

.addCard {
	background: #fff;
	text-align: center;
	font-size: 20px;
	font-family: "Avenir-Medium";
	border-radius: 8px;
}

.addCard h6 {
	padding-top: 20px;
	padding-bottom: 20px;
}

.InfoImage img {
	max-height: 200px;
	object-fit: cover;
	width: 100%;
	position: relative;
	-moz-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

.InfoImage {
	overflow: hidden;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	transition: transform 300ms ease-in-out;
}

.InfoImage:hover img {
	/* overflow: hidden; */
	-moz-transform: scale(1.1);
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	border-radius: 8px;
}

.pricingCat {
	margin-bottom: 15px;
	border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
	padding-bottom: 13px;
}

.ticket-type {
	font-size: 18px;
	color: #4f4f4f;
}

.ticket-price {
	font-size: 20px;
	color: #4f4f4f;
	font-family: "Avenir-Medium";
}

.numberTicket .form-control {
	/* width: 75%; */
	float: right;
	background: transparent;
}

.numberTicket .form-control:focus {
	border-color: rgba(0, 0, 0, 0.15);
	box-shadow: unset;
}

.numberTickets {
	text-align: right;
}

p#calender-d {
	margin-top: -12px;
}

.priceCategory {
	margin-top: 35px;
	margin-left: 25px;
	margin-right: 25px;
}

.event-social {
	padding-top: 20px;
	padding-bottom: 20px;
}

/* button span {
  color: #74AA50;
  padding-left: 25px;
  font-size: 25px;
  position: absolute;
  right: -55%;
  top: -70%;
  background: #fff;
  padding: 20px;
  border-radius: 50%;
  background: white;
  padding: 12px 15px;
  border-radius: 50%;
} */

.modal {
	background: rgba(69, 69, 69, 0.88);
}

.modal-content {
	border-radius: 10px;
}

.modal-btn {
	margin: -17px;
}

#formWrapper {
	background: rgba(0, 0, 0, 0.2);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	transition: all 0.3s ease;
}

.darken-bg {
	background: rgba(0, 0, 0, 0.5) !important;
	transition: all 0.3s ease;
}

div#form {
	background-color: #fff;
	margin: auto;
	border-radius: 5px;
}

div.form-item {
	position: relative;
	display: block;
	margin-bottom: 20px;
}

input {
	transition: all 0.2s ease;
}

input.form-style {
	color: #8a8a8a;
	display: block;
	width: 100%;
	height: 44px;
	padding: 5px 20px;
	border: 1px solid #ccc;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	-moz-background-clip: padding;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	background-color: #fff;
	font-size: 105%;
	letter-spacing: 0.8px;
}

@media only screen and (min-width: 600px) {
	.voucher-submitBtn{
		position: relative;
		top: -51px;
		left: 260px;
	}			
}

.voucher-submitBtn{
    margin-top: 10px;
    margin-bottom: 50px;
}

input.voucherform-style {
	color: #8a8a8a;
	display: block;
	height: 44px;
	padding: 5px 20px;
	border: 1px solid #ccc;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	-moz-background-clip: padding;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	background-color: #fff;
	font-size: 105%;
	letter-spacing: 0.8px;
}

div.form-item .form-style:focus {
	outline: none;
	border: 1px solid #74aa50;
	color: #a5a5a5;
}

div.form-item p.formLabel {
	position: absolute;
	left: 26px;
	top: 10px;
	transition: all 0.4s ease;
	color: #a5a5a5;
}

.formTop {
	top: -11px !important;
	left: 26px;
	background-color: #fff;
	padding: 0 5px;
	font-size: 14px;
	color: #74aa50 !important;
}

.formStatus {
	color: #a5a5a5 !important;
}

input[type="submit"].login {
	width: 100%;
	padding-top: 15px;
	padding-bottom: 15px;
	-moz-background-clip: padding;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	background-color: #74aa50;
	border: 1px solid #74aa50;
	border: none;
	color: #fff;
	font-weight: bold;
	text-transform: capitalize;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

input[type="submit"].login:hover {
	background-color: #346513;
	cursor: pointer;
	transition: all 0.4s ease;
	box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.21);
}

input[type="submit"].login:focus {
	outline: none;
}

.pull-right {
	float: right;
}

.pull-left {
	float: left;
}

.forgot {
	padding-top: 15px;
	margin-bottom: 20px;
}

.forgot a {
	color: #74aa50;
}

h4.modalHeader,
h4.modalBottom,
h4.signupmodalBottom {
	position: absolute;
	top: 60px;
	left: 0;
	right: 0;
	text-align: center;
	color: white;
	font-size: 35px;
	font-family: "Avenir-Heavy";
}

.modal-title {
	color: black !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

h4.modalBottom {
	top: 130%;
	font-size: 18px;
	font-family: "Avenir-Medium";
}

h4.signupmodalBottom {
	top: 117%;
	font-size: 18px;
	font-family: "Avenir-Medium";
}

h4.modalBottom span a,
h4.signupmodalBottom span a {
	color: #74aa50 !important;
}

#signupModal h4.modalHeader {
	top: 0;
}

#buyTicketModal h5.modal-title,
#downloadTicketModal h5.modal-title {
	color: #333;
	font-family: "Avenir-Medium";
	font-size: 25px;
}

#buyTicketModal div.modal-header,
#downloadTicketModal div.modal-header {
	display: block;
	background: rgba(182, 178, 178, 0.05);
}

p.ticket-info {
	font-size: 0.9rem;
	color: #74aa50;
	cursor: pointer;
}

p.ticket--info {
	font-size: 0.9rem;
	cursor: pointer;
}

.address {
	font-size: 1.1rem;
	color: #4f4f4f;
	font-family: "Avenir-Medium";
}

#buyTicketModal button span,
#downloadTicketModal button span {
	color: #000;
	font-size: 1.5625rem;
	border-radius: 50%;
}

#buyTicketModal .close,
#downloadTicketModal .close {
	opacity: 9;
}

.ticketInfo h6 {
	font-size: 1.5rem;
	color: #4f4f4f;
	font-family: "Avenir-Medium";
}

.ticketInfo p,
.ticketInfo div {
	font-size: 0.875rem;
	color: #4f4f4f;
	font-family: "Avenir-Roman";
}

.ticketInfo div.success-icon {
	font-size: 25px !important;
	color: #74aa50;
	vertical-align: middle;
	padding-left: 20px;
}

.ticketInfo div:nth-child(2) {
	font-size: 0.875rem;
	font-family: "Avenir-heavy";
}

.ticketInfos div:nth-child(2) {
	font-size: 0.875rem;
	font-family: "Avenir-Roman";
}

.ticketInfo div span {
	display: block;
	font-size: 11px;
}

.ticketInfo p span a {
	color: #74aa50;
	font-family: "Avenir-Medium";
}

.ticketInfoSection {
	border-radius: 8px;
	border: 1px solid #e5e5e5;
	background: rgba(251, 251, 251, 0.7);
}

h5.ticketTypeHeader {
	color: #4f4f4f;
	font-size: 1.25rem;
	padding: 16px;
	border-bottom: 1px solid #e5e5e5;
	background: white;
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
}

div.ticketTypeHeader {
	color: #4f4f4f;
	font-size: 1.25rem;
	background: white;
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
}

form.ticketForm {
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 10px;
	background: rgba(251, 251, 251, 0.7);
}

form.ticketForm div#form {
	background: rgba(251, 251, 251, 0.7);
}

.ticketCard {
	background: #fafafa;
	margin-top: -30px;
	padding-top: 30px;
	margin-bottom: -15px;
	border-bottom-right-radius: 10px;
}

.save {
	width: 100%;
	color: #74aa50;
}

p.ticketTerm {
	font-size: 12px;
	color: #828282;
}

.b-radius {
	border-radius: 8px !important;
}

a#savedTicket-tab {
	border-top-right-radius: 4px !important;
	border-bottom-right-radius: 4px !important;
}

.ticketPrice {
	float: right;
	text-align: right;
}

p.ticket--info span {
	padding: 0 10px;
	font-size: 0.4rem;
}

div.navlink {
	position: absolute;
	top: 75px;
	background: #000000;
	padding-top: 10px;
	box-shadow: 0px 3px 6px rgba(16, 16, 16, 0.66);
}

div.Dashboardbody {
	/*position: absolute;
	top: 28%;*/
	padding-top: 20px;
	padding-bottom: 20px;
	margin-top: 80px;
	margin-bottom: 80px;
}

a.nav-link {
	color: white !important;
}

a.nav-link:hover {
	color: #74aa50 !important;
}

a.nav-link.active {
	color: #74aa50 !important;
	/* border-bottom: 1.5px solid #74aa50; */
}

div.navlink .nav-link {
	padding-bottom: 15px;
}

.marginless {
	margin-left: 15px;
	margin-left: 15px;
}

.nav-tabs {
	border-bottom: none;
}

.dashboardCard {
	background: #fff;
	border-radius: 8px;
	margin-top: 25px;
}

.dashboardCard2 {
	padding-top: 15px;
}

.dashboardCard h5,
.dashboardCard2 h5 {
	color: #4f4f4f;
}

.quicklinks nav a.nav-link {
	color: #74aa50 !important;
	padding-left: 0px !important;
}

.quicklinks nav a.nav-link:before {
	content: "\f105" !important;
	font-family: FontAwesome !important;
	padding-right: 5px;
	font-size: 12px;
}

.quicklinks a.nav-link:focus {
	color: #4f4f4f !important;
	border-bottom: none;
	-moz-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

.quicklinks a.nav-link:hover {
	color: #4f4f4f !important;
	-moz-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

.salesReport {
	text-align: center;
	margin-top: 30px;
	margin-bottom: 30px;
}

.event-tab .nav-link {
	background: #fff;
	color: #4f4f4f !important;
	border-color: #4f4f4f !important;
}

.event-tab .nav-link.active {
	background: #fff;
	color: #74aa50 !important;
	border-color: #74aa50 !important;
}

.event-tab .nav-link:first-child {
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
	border-bottom-right-radius: 0px;
	border-top-right-radius: 0px;
}

.event-tab .nav-link:nth-child(2) {
	border-bottom-left-radius: 0px;
	border-top-left-radius: 0px;
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
}

nav.myEvents .event-tab .nav-link:nth-child(4) {
	border-bottom-left-radius: 0px;
	border-top-left-radius: 0px;
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
}

nav.myEvents .event-tab .nav-link:nth-child(2),
nav.myEvents .event-tab .nav-link:nth-child(3) {
	border-radius: 0px;
}

nav.myEvents .event-tab form {
	margin-left: 35px;
}

nav.myEvents .event-tab form .form-control {
	height: calc(2.25rem + 5px);
}

.my-events-d {
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
	background: white;
}

p#draft {
	font-family: "Avenir-Heavy";
	color: #cac100;
}

img.user__ {
	border-radius: 50%;
}

span.user__name {
	margin-left: 10px;
}

.user__ a.dropdown-toggle::after {
	display: none !important;
}

.user__ {
	margin-top: -1px;
}

.dropdown-menu {
	min-width: 13rem;
}

/* caret style */
.caret {
	position: relative;
}

/* dropdown style */
.dropdown.open .caret:before,
.dropdown.open .caret:after {
	content: "";
	position: absolute;
	display: block;
	width: 0;
	height: 0;
	border-width: 7px 8px;
	border-style: solid;
	border-color: transparent;
	z-index: 1001;
}
.dropdown.open .caret:before {
	bottom: -17px;
	right: -8px;
	border-bottom-color: #ccc;
}
.dropdown.open .caret:after {
	bottom: -18px;
	right: -8px;
	border-bottom-color: #fff;
}

/* navbar style */
.navbar-nav > li > .dropdown-menu {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}
.navbar-nav > .open > a .caret:before {
	bottom: -23px;
}
.navbar-nav > .open > a .caret:after {
	bottom: -24px;
}

/* RESPONSIVENESS*/

/* @media (max-width: 444px) {
	.navbar-toggler {
		margin-right: -30px;
	}
	.navbar-brand img {
		margin-right: -30px !important;
	}
} */

@media (max-width: 922px) {
	.search-section > form > div.input-group {
		width: 16%;
	}
	input#question {
		width: 40%;
	}
	/* input#time {
		border-bottom-right-radius: 8px;
		border-top-right-radius: 8px;
	} */
	/* .src-btn {
		border-bottom-left-radius: 5px;
		border-top-left-radius: 5px;
	} */
	.input-group-prepend {
		display: none;
	}
	.search-calender {
		display: none;
	}
	.search-location {
		display: none;
	}
}

@media (max-width: 505px) {
	.search-section > .form-inline {
		margin-left: 0px;
	}
	.search-section > form > div.input-group {
		width: 27%;
	}
}

@media (max-width: 524px) {
	.search-calender {
		display: block;
	}
	.search-location {
		display: block;
	}
	input#location,
	input#time {
		padding-left: 0px !important;
	}
}

@media only screen and (max-width: 770px) {
	.event-description-title-top {
		display: none;
	}

	.event-description-title-bottom {
		display: block;
	}
}

@media only screen and (min-width: 770px) {
	.event-description-title-top {
		display: block;
	}

	.event-description-title-bottom {
		display: none;
	}
}


@media only screen and (max-width: 992px) {
	.event-pricing {
		margin-top: 55px;
	}
}

.nav_bar {
	position: relative !important;
	/* display:  */
}

.topPadding {
	margin-top: 5rem;
}

div.dashboardFilter button {
	height: 44px;
	margin-left: 20px;
	background: #fff;
	border: none;
	color: #4f4f4f;
}

div.dashboardFilter button:hover,
div.dashboardFilter button:focus {
	background: #fff;
	border: none;
	color: #74aa50;
}

div.dashboardFilter button.btn-secondary:not(:disabled):not(.disabled):active {
	background: #fff;
	border: none;
	color: #74aa50;
}

div.dashboardFilter.show > .btn-secondary.dropdown-toggle {
	background: #fff;
	border: none;
	color: #74aa50;
}

div.dashboardFilter .dropdown-menu.show {
	width: 520px;
	padding: 20px;
	margin-top: 10px;
}

div.dashboardFilter .form-inline .input-group {
	width: 47%;
}

div.dashboardFilter .input-group-text {
	border-bottom-left-radius: 2px;
	border-top-left-radius: 2px;
	border-left-width: 1px;
	border-right-width: 1px;
}

.dateFilter {
	padding-left: 22px;
	padding-top: 5px;
}

.dropdown-menu.show .row {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding-top: 20px;
	padding-bottom: 10px;
	margin-left: 10px;
	margin-right: 10px;
}

.colorred {
	color: red;
}

.cancel a {
	color: #a4a4a4;
	border-right: 1px solid rgba(0, 0, 0, 0.1);
	padding-right: 16px;
}

.cancel a:hover {
	color: #74aa50;
}

.apply a {
	color: #74aa50;
}

.apply a:hover {
	color: #a4a4a4;
}

.successCard {
	padding: 30px 20px;
	margin: 50px 0px;
	background: url('./image/background.png');
	background-color: #fff;
	background-position: 100% 100%;
	background-size: cover;
	webkit-box-shadow: 2px 0px 15px #000000;
	box-shadow: 2px 0px 15px #000000;
	border-radius: 8px;
}

.successBckg {
	background: url('./image/background.png');
	background-position: 100% 100%;
	background-size: cover;
}

.successCard h4 {
	font-family: "Avenir-Medium";
	font-size: 25px;
	text-align: center;
	color: #74aa50;
}

.successCard h6 {
	font-family: "Avenir-Medium";
	font-size: 18px;
	text-align: center;
	color: #4f4f4f;
	padding-top: 22px;
}

.successlides p:first-child,
.successlides1 p:first-child,
.successlides2 p:first-child,
.successlides3 p:first-child {
	font-size: 17px;
	font-family: "Avenir-Medium";
	text-align: center;
}

.successlides p:nth-child(2),
.successlides1 p:nth-child(2),
.successlides2 p:nth-child(2),
.successlides3 p:nth-child(2) {
	font-size: 14px;
	font-family: "Avenir-Roman";
	text-align: center;
}

div.slick-list {
	height: 95px;
}

.usps {
	margin-top: 25px;
	margin-bottom: 50px;
}

.successlides {
	width: 90% !important;
}

.successlides1 {
	width: 82% !important;
}

.successlides2 {
	width: 71% !important;
}

.successlides3 {
	width: 67% !important;
}

.slick-dots li.slick-active button:before {
	opacity: .75;
	color: #74aa50 !important;
}

.modal-footer {
	display: none;
}

.modal-content {
	border: unset !important;
}

.modal-body {
	padding: 8px !important;
}

#buyTicketModal .modal-body {
	padding: 15px !important;
}

/* Category Section */

.categoryHeader {
	border-bottom: 1px solid rgba(147, 142, 142, 0.1);
	margin-top: 50px;
	margin-bottom: 30px;
	margin-left: 0px;
	margin-right: 0px;
	padding-bottom: 15px;
}

.cHeader h4 {
	font-size: 25px;
	color: #fff;
}

.cFilter label {
	color: #fff;
	font-size: 16px;
}

.cHeader {
	display: inline-block;
}

.pagination-m {
	/* margin-left: -15px; */
	border-top: 1px solid;
}

.cFilter {
	float: right;
	display: inline-block;
	position: absolute;
	right: 0;
	margin-right: 30px;
}

.divider {
	margin-top: 30px;
	margin-bottom: 15px;
}

.notFound {
	color: #fff;
	font-size: 17px;
	font-family: "Avenir-Medium";
}

#notFound .mb-5 {
	margin-bottom: 6.71rem !important;
}

.innerSearch #location {
	width: 151px;
}

#comingSoon {
	background-color: #f1f1f1 !important;
}

.src-btn a {
	color: #fff;
}


.img-fluid {
    width: 100%;
}


.createDiv {
	border: 1px solid rgba(182, 178, 178, 0.45);
	width: 100%;

}

.create__event {
	padding-left: 30px;
}

.freeheader1 {
	background: #e8e8e8;
    max-width: 100%;
	padding: 8px 29px;
	color: #74aa50;
	border-top-left-radius: 4px;
}

.freeheader2 {
	background: #e8e8e8;
    max-width: 100%;
	padding: 8px 29px;
	color: #74aa50;
	border-top-right-radius: 4px;
}
.event-reg {
	padding: 28px;
}

p.event-regh  {
	color: #f7f7f7;
	font-size: 20px;
}
.free .col-lg-8,
.free .col-lg-4  {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.free .col-lg-8 {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.free .col-lg-4 {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}


.textColor {
	color:#e0e0e0;
}



.foot {
	position: fixed;
	/* bottom: 50px; */
	padding: 20px 0;
	font-size: 36px;
	transition: all .2s;
	z-index: 1000001;
left: 0;
right: 0;
/* center all inline content */
text-align: center;
}

.foot > img {
		display: inline-block;
		text-align: left;
}
.foot .footclose {
	position: absolute;
	right: 0;
	left: 0;
	text-align: center;
	font-size: 15px;
	font-weight: light;
	text-decoration: none;
	color: black;
	transition: all .3s;
	font-weight: 600;
	margin-top: -20px;
}
.foot .footclose:hover {
	color: white;
}
.foot .footclose:active {
	color: #ff99ff;
}

.scrolled {
	bottom: 0px;
}

.gone {
	transition: all 300;
	opacity: 0;
}


::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #909;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #909;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #909;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #909;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:    #909;
}

::placeholder { /* Most modern browsers support this now. */
   color:    #909;
}
