.btn-save__create-event {
	background: none;
	border: 1px solid #74aa50 !important;
	border-radius: 4px !important;
	color: #fff;
	padding-left: 20px !important;
	padding-right: 20px !important;
	margin-left: 1em !important;
	font-size: 15px !important;
  }

  .btn-save__create-event {
	color: #74aa50;
  }
  .label-column img {
	text-align: center;
	display: block;
	align-content: center;
	margin: auto;
  }

  .btn-save__create-ticket {
	background: none;
	border: 1px solid #74aa50 !important;
	border-radius: 4px !important;
	color: #fff;
	padding-left: 20px !important;
	padding-right: 20px !important;
	margin-left: 1em !important;
	font-size: 15px !important;
  }

  .btn-save__create-ticket {
	color: #74aa50;
  }

  .inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
  }

  .inputfile + label {
	cursor: pointer;
  }

  .col-12.form-container {
	padding-left: 5em;
	padding-right: 5em;
  }

  h4.event-header__create-event {
	color: white;
	padding-bottom: 1em;
	font-size: 25px;
  }

  .header-section__create-event {
	width: 100% !important;
	background: #1f1d23;
	border-top: 1px solid rgba(255, 255, 255, 0.114);
	border-bottom: 1px solid rgba(255, 255, 255, 0.114);
	display: flex;
	margin-left: 0 !important;
	color: white;
  }

  h4.event-description__create-event {
	padding-top: 8px;
	margin-left: 40px;
  }

  .event-description__button__create-event {
	margin-left: auto;
	margin-right: 3em;
	padding-top: 4px;
  }

  .form-container {
	margin-top: 4em;
	margin-bottom: 2em;
	margin-left: auto;
	margin-right: auto;
  }

  .form-content {
	padding: 3em 3em;
  }

  .form-content__title {
	margin-bottom: 30px;
	color: #222;
  }

  .start .input-group-text,
  .stop .input-group-text {
	border-left-width: 1px;
	border-bottom-left-radius: 5px;
	border-top-left-radius: 5px;
  }

  h3.form-content__title {
	font-size: 25px;
  }

  .bg__shadow {
	box-shadow: 0px 0px 15px rgba(236, 255, 223, 0.32);
  }
  .form-content__label {
	margin-bottom: 5px;
	margin-top: 10px;
	color: #1d1d1d !important;
	font-size: 19px;
	font-family: "Avenir-Roman";
  }

  .mce-panel {
	border-radius: 5px;
  }

  .mce-menubar {
	display: none !important;
  }

  .mce-statusbar .mce-container-body {
	display: none !important;
  }

  .event-tag {
	margin-top: 2em;
  }

  .event-select {
	height: 45px !important;
	border-radius: 5px !important;
	border: 1px solid #ccc;
  }

  .event-direction {
	width: 100%;
	margin-top: 26px;
	height: 19.3em;
  }

  span.uploadDesc {
	font-size: 13px;
  }

  label.filebuttons {
	margin-left: 0px;
	margin-top: 0px;
  }

  .create-upload {
	background-color: #f6f6f6;
	padding-top: 50px;
	padding-bottom: 50px;
	border-radius: 5px;
	border: 1px dashed rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: column;
	align-items: center;
  }

  .upload-icon {
	margin-bottom: 10px;
	/* height: 100px; */
	margin-left: auto;
	margin-right: auto;
  }
  img.upload-icon {
	/* margin-bottom: 10px; */
	height: 100px;
	/* margin-left: auto; */
	/* margin-right: auto; */
  }
  .event-image__upload {
	margin-left: 3px !important;
	margin-right: 3px !important;
  }

  .nice-job {
	text-align: center;
	margin-top: 2em;
  }

  .nice-job-text {
	color: white;
  }

  .ticket-offer__icon {
	font-size: 22px;
	margin-top: 10px;
  }

  .ticket-offers__body {
	background-color: #e8e8e8;
	padding-top: 10px;
	padding-bottom: 15px;
	margin-left: -3em;
	margin-right: -3em;
  }

  .ticket-offer__icon-left {
	padding-left: 15px;
  }

  .ticket-offers__title {
	text-align: center;
  }

  .btn-ticket-offers {
	display: flex;
	justify-content: center;
  }

  .ticket-cog {
	color: black !important;
  }

  .ticket-label {
	margin-top: 15px;
  }

  .ticket-number {
	margin-top: -15px;
	position: absolute;
  }

  .ticket-button__status {
	font-size: 15px !important;
	width: 50%;
	color: black;
	background: #e8e8e8;
	padding-top: 10px;
	padding-bottom: 10px;
  }

  .ticket-button__status:first-child {
	border-radius: 4px 0 0 4px;
  }

  .ticket-button__status:last-child {
	border-radius: 0 4px 4px 0;
	background: #ffffff;
	border: 1px solid #e8e8e8;
  }

  .ticket-button__channel {
	font-size: 15px !important;
	color: black;
	background: #e8e8e8;
	padding-top: 10px;
	padding-bottom: 10px;
	width: 33%;
  }

  .ticket-desc {
	width: 100%;
	outline: transparent;
	border-radius: 8px;
	border: 2px solid #e8e8e8;
  }

  .freeticket-form {
	/* margin-top: 28px; */
	display: flex;
	align-items: center;
  }

  .card-ticket {
		height: auto;
		padding-left: 0;
		padding-right: 0;
  }

  .card-ticket__details {
		background-color: rgb(232, 232, 232);
		margin-top: 1rem;
		padding: 0px 2rem;
		margin-left: -3rem;
		margin-right: -3em;
	}

	.card-ticket__card {
		height: auto;
	}

  .btn-edit-class {
	background-color: #74aa50;
  }

  .label-column {
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;
  }

  input[type="file"] {
	margin-left: auto;
	margin-right: auto;
  }

  .create-event-card {
	height: 120px !important;
  }

  .btn-bottom {
	margin-bottom: 2em;
  }

  .create-event-desc {
	width: 100%;
	border-radius: 8px;
	border: 2px solid #e8e8e8;
	outline: transparent;
  }

  .create-event-cat {
	margin-top: 30px;
  }

  .tickets-allow {
	margin-top: 20px !important;
  }

  .confirmation-message {
	background-color: #74aa50;
	border-radius: 30px;
	padding: 7px;
  }

  /*** SWITCH STYLE ***/

  .switch-field {
	/* font-family: "Lucida Grande", Tahoma, Verdana, sans-serif; */
	padding: 0;
	overflow: hidden;
  }

  .switch-title {
	margin-bottom: 6px;
  }

  .switch-field input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
  }

  .switch-field label {
	float: left;
  }

  .switch-field label {
	display: inline-block;
	width: 33%;
	background-color: #ffffff;
	color: rgba(0, 0, 0, 0.6);
	font-weight: normal;
	text-align: center;
	text-shadow: none;
	padding: 8px 14px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3),
	  0 1px rgba(255, 255, 255, 0.1);
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-ms-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
  }

  .switch-field label:hover {
	cursor: pointer;
  }

  .switch-field input:checked + label {
	background-color: #e8e8e8;
	-webkit-box-shadow: none;
	box-shadow: none;
  }

  .switch-field label:first-of-type {
	border-radius: 4px 0 0 4px;
  }

  .switch-field label:last-of-type {
	border-radius: 0 4px 4px 0;
  }

  .note-popover .popover-content,
  .card-header.note-toolbar {
	background: #0f0f0f !important;
  }

  /*RESPONSIVENESS*/

  @media (max-width: 991px) {
	.resp-column {
	  flex-direction: column;
	}
	.col-6 {
	  max-width: 100% !important;
	}
  }

  @media (max-width: 767px) {
	.create-upload__right {
	  margin-top: 1em;
	}
  }

  @media (max-width: 530px) {
	.col-12.form-container {
	  padding-left: 0;
	  padding-right: 0;
	}
  }
