@media (max-width: 320px) {
	.cat-card {
		min-height: 60px !important;
	}

	.cat-card > h5 {
		top: 0px;
		padding-top: 15px;
	}

	.cat-name a {
		font-size: 14px;
	}
}
@media (max-width: 445px) {
	.save-btn {
		margin-left: 0px;
		margin-top: 20px;
	}
}
@media (min-width: 576px) {
	#buyTicketModal .modal-dialog,
	#downloadTicketModal .modal-dialog {
		max-width: 870px;
		margin: 1.75rem auto;
	}
}
@media only screen and (min-width: 766px) and (max-width: 841px) {
	.my-events-d .col-sm-7 {
		margin-left: 20px;
	}
}

@media only screen and (max-width: 778px) {
	.comingSoon p {
		width: 100%;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.innerSearch #location {
		width: 74px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 741px) {
	.list-group-level2 .list-group-item {
		padding-left: 40px !important;
	}
	nav.myEvents .event-tab form {
		margin-left: 0px;
		margin-top: 15px;
		width: 100%;
	}
	nav.tickets .event-tab form {
		margin-left: 35px;
		margin-top: 0px !important;
		width: auto !important;
	}
	.account-settings nav a.nav-link {
		padding-left: 15px !important;
	}
}

@media only screen and (max-width: 575px) {
	/* .carousel-inner img {
		width: 100%;
		object-fit: contain;
		object-position: center;
	} */

	.search-section {
		border-radius: 0px;
		margin-top: -26px;
		box-shadow: unset;
	}
	.popularEvents {
		margin-top: 295px;
	}

	.homesearch-input .input-group-prepend {
		border-top: 0;
		border-top-left-radius: 0px;
		border-bottom: 0px;
		border-bottom-left-radius: 0px;
	}
	.apps {
		text-align: center;
	}
	.page-title {
		font-size: 18px;
	}
	.bottomSpace {
		margin-bottom: 30px;
	}
	.event-attendees-container {
		margin-left: 15px !important;
	}

	.event-attendees-container h2 {
		font-size: 22px;
	}

	.eventmanager-navbar {
		display: -ms-flexbox !important;
		display: flex !important;
		justify-content: flex-start !important;
		padding-left: 15px !important;
	}
	.eventmanager-title {
		margin-left: 15px !important;
		margin-right: 15px !important;
		font-size: 22px;
	}

	.eventmanager-row {
		margin-bottom: 60px;
	}

	.row.section-one,
	.row.section-two {
		margin-left: 15px !important;
	}
	.event-tab .nav-link:first-child,
	nav.myEvents .event-tab .nav-link:nth-child(2),
	.event-tab .nav-link:nth-child(2),
	nav.myEvents .event-tab .nav-link:nth-child(3),
	nav.myEvents .event-tab .nav-link:nth-child(4) {
		text-align: center;
		width: 50%;
	}
	.pwd_S .basicSetting,
	.acctSetting .basicSetting {
		padding-right: 15px;
	}
	.basicsettings-container {
		padding-left: 2em;
		padding-bottom: 2rem !important;
	}
	.account-settings nav a.nav-link {
		padding-left: 15px !important;
	}
	.my-events-d .col-sm-7 {
		margin-top: 10px;
	}
	.numberTickets {
		text-align: left;
	}

	.sideBar {
		padding-top: 2px !important;
		border-top: 0.5px solid;
	}

	.mt-5.basicSetting {
		margin-top: 2rem !important;
		margin-bottom: 3rem;
	}
	nav.myEvents .event-tab form {
		margin-left: 0px;
		margin-top: 15px;
		width: 100%;
	}

	#merchandize:after {
		content: "unset";
		display: none;
	}

	.search-section > .form-inline {
		margin-left: 10px;
	}
	#free_events {
		margin-top: 40px;
	}
	.search-section h4 {
		font-size: 25px;
	}
	input#question,
	input#question1 {
		border-radius: 4px;
		width: 100%;
	}

	input#question {
		border-bottom-right-radius: 4px !important;
		border-top-right-radius: 4px !important;
	}

	input#location,
	input#time {
		border-bottom-right-radius: 4px !important;
		border-top-right-radius: 4px !important;
		/* border-bo */
	}
	.search-section > form > div.input-group {
		width: 100%;
	}
	.src-btn {
		width: 100%;
		border-bottom-left-radius: 4px;
		border-top-left-radius: 4px;
	}

	.input-group-prepend {
		display: flex;
	}

	.input-group-text {
		background-color: #fff;
		border: 1px solid #e0e0e0;
		border-bottom-left-radius: 4px;
		border-top-left-radius: 4px;
		border-left-width: 1px;
		border-right-width: 0px;
	}
	footer h5 {
		text-align: center;
		padding-top: 20px;
	}

	footer ul.list-group {
		text-align: center;
	}
}
/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
}

/* Extra extra small devices (phones, 420px and down) */

@media only screen and (max-width: 420px) {
	.ticketPrice {
		float: unset;
		text-align: unset;
	}

	.cat-card {
		min-height: 97px;
	}

	.cat-name a {
		font-size: 14px;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and own) */

@media only screen and (max-width: 620px) {
	.footer__copyright {
		display: block;
		text-align: center;
	}
	.footer__links {
		display: block;
		text-align: center;
		float: unset !important;
	}
	.input-group-prepend {
		display: flex;
	}
}

/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {

}

@media only screen and (max-width: 992px) {
	footer h5 {
		padding-top: 20px;
	}

	.free .col-lg-8 {

		border-radius: 4px;
		
	}


.freeheader1 {
	border-top-right-radius: 4px;
}

.freeheader2 {
	border-top-left-radius: 4px;
}
	
	.free .col-lg-4 {
		margin-top: 30px;
		border-radius: 4px;
	} 
		
	
}
@media only screen and (max-width: 974px) {
	.navbar-dark .navbar-nav .nav-link {
		color: #fff !important;
		padding-left: unset;
		padding-right: unset;
	}
}
/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 599px) and (max-width: 601px) {
	.d__viewport .col-sm-2,
	.d__viewport .col-sm-3 {
		max-width: 100%;
	}
	.list-group-level2 .list-group-item {
		padding-left: 28px !important;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.categoryHeader .col-sm-2,
	.categoryHeader .col-sm-3,
	.categoryHeader .col-sm-7 {
		max-width: 100% !important;
		flex: 100% !important;
	}
}
