/*.details-background-image {
  margin-top: 75px !important;
}*/

@media (max-width: 991px) {
  .event-pricing-details {
    margin-top: 50px !important;
  }
}

@media (max-width:373px) {
  .col-2.ticket-price {
    padding-left: 2px;
  }
}