.event-side-menu-settings {
  margin-top: 6.5em;
}

.event-side-menu-list > li {
  list-style: none;
  font-size: 13px;
}

.event-side-menu-title {
  color: #fff;
  margin-left: 2em;
  margin-top: 15px;
  margin-bottom: 15px;
}

.event-side-menu-list__items {
  color: #fff;
}

.event-side-menu-logout {
  margin-top: 13em;
  margin-left: -2em;
}

.event-side-menu-list {
  margin-left: 3em;
}

.flex-column {
  border: none !important;
}

.event-attendance {
  margin-left: -2em;
}

.event-li {
  margin-left: 2em;
}

.event-li-li {
  margin-left: -2em;
}

.event-manager__dropdown {
  background-color: transparent !important;
  border: transparent !important;
}

.event-manager__dropdown:active,
.event-manager__dropdown:focus,
.event-manager__dropdown:visited {
  background-color: transparent !important;
  border: transparent !important;
}