#comingBg {
	width: 100%;
	height: 100%;
	margin-top: 18vh;
}

.comingSoon {
	text-align: center;
	color: #e6e6e6;
}

.comingSoon h4 {
	font-family: "Avenir-Heavy";
	padding-top: 20px;
	padding-bottom: 10px;
	font-size: 30px;
}

.comingSoon p {
	font-family: "Avenir-medium";
	padding-bottom: 10px;
	padding-bottom: 30px;
	font-size: 16px;
	width: 50%;
	margin: auto;
}

@media only screen and (max-width: 320px) {
	.comingSoon h4 {
		font-size: 22px;
	}
	.comingSoon p {
		width: 100%;
	}

	img.imgSoon {
		width: 33%;
	}
}

@media only screen and (max-width: 575px) {
	.comingSoon h4 {
		font-size: 22px;
	}

	.comingSoon p {
		width: 100%;
	}

	img.imgSoon {
		width: 26%;
	}
}
