.basicsettings-container {
	/* padding-left: 2em; */
	padding-top: 2.5em;
	padding-bottom: 15em;
	margin-left: 0;
	margin-top: 0;
	margin-bottom: -70px;
}
.basicSetting {
	padding-right: 47px;
	padding-left: 30px;
}

.no__lpadding {
	padding-right: 0px !important;
}

.no__lpadding div.form-item input#current_pwd {
	margin-left: -15px;
}

div.current p.formLabel {
	left: 10px !important;
}

.basicSetting div div form {
	padding-right: 0px !important;
}

.acctSettings .row {
	margin-right: 0 !important;
	margin-left: -15px !important;
}

.account-settings nav a.nav-link {
	color: #fff !important;
	font-size: 18px;
	padding-left: 42px !important;
	padding-top: 20px;
	padding-bottom: 20px;
	/* border-bottom: 1.5px solid #74aa50; */
}

.account-settings nav a.nav-link.active {
	color: #fff !important;
	border-left: 3px solid #74aa50;
	border-bottom: none;
	background-color: rgba(116, 170, 80, 0.18);
}

.account-settings nav a.nav-link:hover {
	background-color: rgba(229, 234, 226, 0.18);
}

.basicsettings-title {
	margin-top: 10px;
	color: white;
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	padding-bottom: 12px;
}

.basicsettings-editicon {
	background-color: #74aa50;
	color: white;
	padding: 1em;
	border-radius: 50%;
	position: absolute;
	margin-left: -4em;
	margin-top: 10em;
}
label.filebutton {
	overflow: hidden;
	position: relative;
	margin-left: 94px;
	margin-top: -25px;
}

label span input {
	z-index: 999;
	line-height: 0;
	font-size: 50px;
	position: absolute;
	top: -2px;
	left: -700px;
	opacity: 0;
	filter: alpha(opacity = 0);
	-ms-filter: "alpha(opacity=0)";
	cursor: pointer;
	_cursor: hand;
	margin: 0;
	padding: 0;
}
.image-upload > input {
	display: none;
}

img.basicsettings-profileimage {
	height: 138px;
	border-radius: 50%;
}

.profileimage-container {
}

.basicsettings-body > h5 {
	color: white;
	padding-top: 15px;
	padding-bottom: 15px;
}

.form-body {
	margin-left: -15px;
}

.section-one {
	padding-top: 1em;
	padding-bottom: 2em;
}

.section-one__a {
	background-color: #fff;
	border-radius: 10px;
	max-width: 45% !important;
}

.section-one__b {
	background-color: #fff;
	border-radius: 10px;
	max-width: 45% !important;
	margin-left: 1em;
}

.section-two__a {
	background-color: #fff;
	border-radius: 10px;
	max-width: 45% !important;
}

.section-two__b {
	background-color: #fff;
	border-radius: 10px;
	/* max-width: 45% !important; */
	/* margin-left: 1em; */
}

.section-one-two__a {
	border-right: 1px solid grey;
	text-align: center;
}

.section-one-two__b {
	text-align: center;
}

.section-one__a-text {
	text-align: center;
}

.section-two__a-image {
	width: 3.4em;
}

.section-two__a-body {
	text-align: center;
}

.section-two__a-text {
	margin-top: 2em;
}

.section-one__a-text > h2,
.section-one-two__a > h2,
.section-one-two__b > h2 {
	margin-top: -10px;
	padding-bottom: 20px;
}

.section-one__a-text > p,
.section-one-two__a > p,
.section-one-two__b > p {
	padding-top: 20px;
}

.section-two__a > p,
.section-two__b > p {
	padding-top: 10px;
}

.event-table-title {
	padding-right: 3em;
}

th {
	background-color: lightgray;
}

.password-container {
	background-color: #181818;
	padding-left: 2em;
	padding-top: 2em;
	margin-left: 0em;
	margin-top: 4.4em;
	margin-bottom: -4em;
}

.password-title {
	margin-top: 10px;
	color: white;
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	padding-bottom: 12px;
}

.password-editicon {
	background-color: green;
	color: white;
	padding: 1em;
	border-radius: 50%;
}

.password-profileimage {
	width: 13em;
	border-radius: 50%;
}

.password-body > h5 {
	color: white;
	padding-top: 15px;
	padding-bottom: 15px;
}

.form-body {
	margin-left: -15px;
}

.password-formbody {
	padding-top: 4em;
}
